import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import NavBar from './navigation/NavBar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";

import { API_URL } from '../constants/Strings';
import { green } from '../constants/Colors';
import SideBar from './navigation/SideBar';
import PuttrSpinner from './common/PuttrSpinner';


const accesories = [
  {title:"iPad", description:"We recommend Apple iPad as the tablet to use for events",price:"$349",link:"https://www.apple.com/shop/buy-ipad/ipad"},
  {title:"Tablet Floor Stand", description:"Mount your tablet and capture video",price:"$899",link:"https://www.amazon.co.uk/dp/B0CJ4LMSCD?ref=ppx_yo2ov_dt_b_fed_asin_title"},
  {title:"Flooring", description:"Frame the putting experience and set up on most surfaces (6'x14' recommended)",price:"$859",link:"https://www.snaplockdancefloors.com/portable-dance-floor-styles/slate-black-dance-floor/"},
  {title:"TV", description:"Big screen TV to mirror the games from your tablet",price:"$399",link:"https://www.bestbuy.com/site/samsung-55-class-7-series-led-4k-uhd-smart-tizen-tv/6401735.p?skuId=6401735&ref=212&loc=1&extStoreId=107&ref=212&loc=1&gclid=Cj0KCQiA2-2eBhClARIsAGLQ2Rn4n3zGT8GgAiLHn6tjao6stfc_CLAh3Z2UfzzCB_OWq06emEWlYFYaAgF9EALw_wcB&gclsrc=aw.ds"},
  {title:"TV Stand", description:"Floor standing mount to set up the TV anywhere",price:"$189",link:"https://fitueyes.com/products/iron-base-large-swivel-tv-stand-s-series-50-80-inch"},
  {title:"iPad TV Adapter", description:"Adapter to mirror iPad to your TV",price:"$69",link:"https://www.apple.com/uk/shop/product/MUF82ZM/A/usb-c-digital-av-multiport-adapter"},
  {title:"iPad Wall Mount", description:"Secure wall mount for tablet if doing a permanent install",price:"$300",link:"https://armodilo.com/products/original/tilt/"},
];

function GuideScreen(props){

  useEffect(() => {

  },[]);


  const renderSteps = () => {
    return(
      <ListGroup as="ol" numbered>
        <ListGroup.Item variant="primary" as="li">Set your club's information on the <Link to="/branding" className="link">BRANDING</Link> page</ListGroup.Item>
        <ListGroup.Item variant="primary" as="li"><a href="https://youtu.be/NCib-cHMqNg" target="_blank" className="link">WATCH THIS</a> setup video and follow <b>"TABLET SETUP"</b> instructions below</ListGroup.Item>
        <ListGroup.Item variant="primary" as="li">Optinally purchase items from our <b>"EVENT ACCESORIES"</b> list below</ListGroup.Item>
      </ListGroup>
    );
  }
  const renderTabletSetup = () => {

    let cid = "[CLUB_NAME]";
    if (props.app.clubId){
      cid = props.app.clubId;
    }


    return(

      <div style={{alignItems:'center'}}>

        <ListGroup as="ol" numbered>
          <ListGroup.Item as="li">Download the PUTTR app from the app store by searching Puttr</ListGroup.Item>
          <ListGroup.Item as="li">Launch the app</ListGroup.Item>
          <ListGroup.Item as="li">Press the PUTTR box above the words GAME ON 5 times</ListGroup.Item>
          <ListGroup.Item as="li">Press the KIOSK MODE button</ListGroup.Item>
          <ListGroup.Item as="li">Enter <b>{cid}</b> as the Club ID and press LOOKUP button</ListGroup.Item>
          <ListGroup.Item as="li">Select the location</ListGroup.Item>
          <ListGroup.Item as="li">Select the PUTTR that you want iPad to connect to</ListGroup.Item>
          <ListGroup.Item as="li">Press ASSIGN button</ListGroup.Item>
          <ListGroup.Item as="li">Press START GAME button</ListGroup.Item>
        </ListGroup>

        <div className="label" style={{marginTop:10}}>* To reset settings, press on the PUTTR logo at the top of the iPad screen 7 times</div>
      </div>
    );
  }
  const renderAccesories = () => {

    return accesories.map((item, index) => {

      return(

        <tr key={index}>
          <td>{item.title}</td>
          <td>{item.description}</td>
          <td>{item.price}</td>
          <td><a href={item.link} target="_blank" className="link">MORE INFO</a></td>

        </tr>
      );

    });


  }
  const renderAccesoriesList = () => {
    return(

      <div style={{alignItems:'center'}}>

        <div className="label" style={{marginTop:10}}>Optional accesories that we recommend for your event with a link where to buy</div>

        <Table striped bordered hover>

          <thead>
            <tr>

              <th>ITEM</th>
              <th>Description</th>
              <th>COST (USD)</th>
              <th>LINK</th>
            </tr>
          </thead>
          <tbody>

            {renderAccesories()}

          </tbody>

        </Table>


      </div>
    );
  }

  const renderHome = () => {

      return(

        <div style={{ display: 'flex', height: '100%' }}>

          <Container fluid className="align-items-center justify-content-center">

            <div style={{marginTop:20, display: 'grid', placeItems: 'center'}}>

              <h1>CLUB SETUP GUIDE</h1>

              <div style={{marginTop:20}}>

                {renderSteps()}

              </div>

              <div style={{maxWidth:600, minWidth:350, marginTop:20}}>

              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>TABLET SETUP</Accordion.Header>
                  <Accordion.Body>
                    {renderTabletSetup()}
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>

              </div>

              <div style={{maxWidth:600, minWidth:350, marginTop:20}}>

                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>EVENT ACCESORIES</Accordion.Header>
                    <Accordion.Body>
                    {renderAccesoriesList()}

                    <div className="label" style={{marginTop:10}}>* These are just recommendations, PUTTR does not sell or support any of these items</div>

                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>


              </div>

              <div style={{alignItems:'center', marginTop:20}}>

                Any questions? Contact your caddie: <b>caddie@puttr.co</b>

              </div>

            </div>

          </Container>

          <SideBar />

        </div>

      );


  }



  return(

    <Container fluid className="align-items-center justify-content-center">

      <NavBar />

      {renderHome()}

    </Container>

  );
}

const mapStateToProps = state => {
  return {
    app: state.app
  }
};

export default connect(mapStateToProps, null)(GuideScreen);
